import React from 'react'
import code_01 from '../../assets/image/code_01.jpg'
import code_02 from '../../assets/image/code_02.jpg'
import code_03 from '../../assets/image/code_03.jpg'
import code_04 from '../../assets/image/code_04.jpg'
// import code_05 from '../../assets/image/code_05.jpg'
import code_06 from '../../assets/image/code_06.jpg'
import code_07 from '../../assets/image/code_07.jpg'
import code_08 from '../../assets/image/code_08.jpg'
import code_09 from '../../assets/image/code_09.jpg'
import code_10 from '../../assets/image/code_10.jpg'
import code_11 from '../../assets/image/code_11.jpg'
import code_12 from '../../assets/image/code_12.jpg'
import code_13 from '../../assets/image/code_13.jpg'
import contact_03 from '../../assets/image/contact_03.png'
import contact_04 from '../../assets/image/contact_04.png'
import contact_05 from '../../assets/image/contact_05.png'
import './contact.less'

export default function contact() {
  return (
    <div>

      <div className="contact-banner">
        <div className="wrap"></div>
      </div>
      <div className="about-box">
        <div className="wrap">

          <div className="content clearfix">
            <p className="con-title">盈诺科技公司联系方式：</p>
            <ul className="contact">
              <li>
                <div className="contact-list">
                  <div className="contact-title">商务中心</div>
                  <div className="list">
                    <span><i>何经理</i>：17507206900（同微信）</span>
                    <span>邮箱：he@yingnuoqian.com</span>
                  </div>
                  <img className="code-img" src={code_13} alt=""/>
                </div>
              </li>
              <li>
                <div className="contact-list">
                  <div className="contact-title">商务中心</div>
                  <div className="list">
                    <span><i>郭经理</i>：18972005559（同微信）</span>
                    <span>邮箱：guo@yingnuoqian.com</span>
                  </div>
                  <img className="code-img" src={code_02} alt=""/>
                </div>
              </li>

              <li>
                <div className="contact-list">
                  <div className="contact-title">商务中心</div>
                  <div className="list">
                    <span><i>覃经理</i>：18872509595（同微信）</span>
                    <span>邮箱：qin@yingnuoqian.com</span>
                  </div>
                  <img className="code-img" src={code_12} alt=""/>
                </div>
              </li>
              <li>
                <div className="contact-list">
                  <div className="contact-title">商务中心</div>
                  <div className="list">
                    <span><i>郑经理</i>：15672533307（同微信）</span>
                    <span>邮箱：zheng@yingnuoqian.com</span>
                  </div>
                  <img className="code-img" src={code_09} alt=""/>
                </div>
              </li>
              {/* <li>
                <div className="contact-list">
                  <div className="contact-title">武汉分公司</div>
                  <div className="list">
                    <span><i>许经理</i>：17701026478（同微信）</span>
                    <span>邮箱：Xu@yingnuoqian.com</span>
                  </div>
                  <img className="code-img" src={code_05} alt="" />
                </div>
              </li> */}

            </ul>
          </div>

          <div className="content clearfix" style={{padding: '20px 0'}}>
            <div className="content-left1">
              <p>如果您有如下需求，可随时联系我们：</p>
              <p>1 电子签名系统技术、业务战略合作，及电子签名私有化部署服务，拥有自己个性化的业务平台！</p>
              <p>2 全程线上公证业务电子化签署，自动验证进件，公证处人工受理、审批、核实、出证等全业务公证流程，系统化数据管理。</p>
              <p>3 为企业量身设计、开发、搭建内部业务、财务全方位管理系统，提高企业管理效率，数据精准化管理。</p>
              <p>4 线上公证业务办理、咨询、合作等相关服务</p>
              <p>5 微信小程序、公众号营销系统及APP定制开发。</p>
              <p>6 软件开发技术外包(金融行业、医美行业、司法政务等行业）。</p>
              <p>7 法务资讯、代理、顾问服务。</p>
              <p>8 区块链技术研发。</p>
            </div>
          </div>


          <div className="about-img">
            <ul className="clearfix">
              <li>
                <img src={contact_03} alt=""/>
              </li>
              <li>
                <img src={contact_04} alt=""/>
              </li>
              <li>
                <img src={contact_05} alt=""/>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  )
}
