import React from 'react'
import partner_01 from '../../assets/image/partner_01.png'
import partner_02 from '../../assets/image/partner_02.png'
import partner_03 from '../../assets/image/partner_03.png'
import partner_04 from '../../assets/image/partner_04.png'
import partner_05 from '../../assets/image/partner_05.png'
import partner_06 from '../../assets/image/partner_06.png'
import partner_07 from '../../assets/image/partner_07.png'
import partner_08 from '../../assets/image/partner_08.png'
import partner_09 from '../../assets/image/partner_09.png'
import partner_10 from '../../assets/image/partner_10.png'
import about_01 from '../../assets/image/about_01.jpg'
import about_02 from '../../assets/image/about_02.jpg'
import about_03 from '../../assets/image/about_03.jpg'
import './about.less'

export default function about() {
  return (
    <div>
      <div className="about-banner">
        <div className="wrap"></div>
      </div>
      <div className="about-box">
        <div className="wrap">
          <div className="content clearfix" style={{padding: '50px 0'}}>
            <div className="content-left">
              <p>“盈诺签”为湖北盈诺网络科技有限公司企业商标，专注于电子签名、司法服务、区块链技术领域的研究与开发，2018年9月上线“盈诺签”电子合同产品系列，目前已有合作单位10000+家，已出具赋强债权合同文书近万份，以“电子签名+司法服务+区块链=盈诺签“为研发主旨，是集电子签名与在线赋强公证为一体的一站式服务，用户通过在线注册认证，获得合法的电子身份后发起在线签约流程，签约完成后即可进一步在线提交第三方公证处申请赋强公证服务，通过有效签署及在线取证等环节后，即可完成在线赋强公证，并可同时获得电子赋强公证证书，日后签约人存在违约时，维权人即可发起强制执行出证操作，并及时送达对应法院立案执行，有效保护的维权人的合法权益。</p>
              <p>“盈诺签“现已成为业务平台和司法单位有效衔接的中间枢纽，数据可线上直接送达公证机构、法院、律师事物所等司法单位，另有专业的公司法务团队提供后期保障，形成线上线下一体化贴心服务，大大提高了使用人和司法单位的工作效率，节约了社会资源，有效解决了传统线下司法模式中的维权成本高、维权难的问题，使企业有了更灵活的发展空间，未来”盈诺签“将更进一步整合广大社会资源，以达成司法政务全线上完成为目标和已任，努力开拓，再创新高！</p>
              <p>湖北盈诺网络科技有限公司位于湖北省宜昌市高新区, 从事高新技术应用领域中的应用系统开发、信息安全服务、支付技术研发等，并于2022年荣获国家高新技术企业称号； 对外提供金融、农业、医美、装饰装修等相关行业信息化领域的技术输出，凭借多年在应用系统开发以及信息安全服务的积累经验，盈诺科技已经成为区域内著名的应用系统解决方案提供商！</p>
              <p>我们竭诚欢迎社会各界有识之士前来调研、洽谈合作！</p>
            </div>
            <div className="content-right">

            </div>
          </div>
          <div className="about-img">
            <ul className="clearfix">
              <li>
                <img src={about_01} alt="" />
              </li>
              <li>
                <img src={about_02} alt="" />
              </li>
              <li>
                <img src={about_03} alt="" />
              </li>
            </ul>
          </div>
        </div>

      </div>
      <div className="partner">
        <div className="wrap">
          <div className="serve-title">
            <h3>合作伙伴</h3>
            <h4>Cooperation partners</h4>
          </div>
          <div className="partner-box">
            <div>
              <img src={partner_06} alt="" />
              <span>|</span>
              <img src={partner_09} alt="" />
              <span>|</span>
              <img src={partner_10} alt="" />
              <span>|</span>
              <img src={partner_01} alt="" />
            </div>
            <div>
              <img src={partner_02} alt="" />
              <span>|</span>
              <img src={partner_03} alt="" />
              <span>|</span>
              <img src={partner_04} alt="" />
              <span>|</span>
              <img src={partner_05} alt="" />
              <span>|</span>
              <img src={partner_07} alt="" />
              <span>|</span>
              <img src={partner_08} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
