import React from 'react'
import './product.less'
import bg_08 from '../../assets/image/bg_08.jpg'

export default function product() {
  return (
    <div>
      <div className="product-banner">
        <div className="wrap"></div>
      </div>

	<div className="wrap">
		<ul className="described">
			<li>
				<div className="titles">盈诺签在线公证服务 </div>
				<div className="serve-box clearfix">
					<div className="serve-right">
						<div>
							<p className="service-title">强制执行</p>
							<div className="serve-content">
								对于经催收未能回收的债权，公证机构在收到维权人的执行出证申请后，经核实后出具电子执行证书或纸质版执行证书，发送至对接的法院系统或对接律所线下送达；法院对执行证书审查确认后，启动执行程序，通过最高人民法院网络执行查控系统查找执行债务人的存款、理财产品等财产。对于经网络执行查控仍未能回收的债权，法院可以依法将债务人列入失信被执行人名单，通过信用惩戒迫使其尽快履行还款义务。
							</div>
						</div>
						<img src={bg_08} alt=""/>
					</div>
					<div className="serve-left">
						<div className="right-box">
							<p className="service-title">电子签约</p>
							<div className="serve-content">通过注册盈诺签账号并通过实名认证，从而获得合法的电子签约身份，在线发起电子签约流程，各签约方完成签署过程后，得到完整可靠的电子签名文件。</div>
						</div>
						<div className="right-box">
							<p className="service-title">在线公证</p>
							<div className="serve-content">
								完成电子签名后可立即在线申请办理赋予债权文书强制执行效力的公证书或普通存证公证书，签约方可在线查看电子公证书，并可根据需要，在线自助申请公证机构出具纸质公证书或纸质强制执行证书。</div>
						</div>
						<div className="right-box">
							<p className="service-title">公证催告及证据固定</p>
							<div className="serve-content">公证处可以代债权人通过短信、邮件、公告等方式向违约方采取一切通知手段，进行违约款项催收，对催收的过程和事实进行证据固定。</div>
						</div>
						<div className="right-box padding-none">
							<p className="service-title">电子数据证据存证</p>
							<div className="serve-content">即盈诺签对电子合同数据的存在或生成时间进行公证证明，证明在“什么时间存在什么内容的电子数据”，防止债务人质疑债权人收集的电子数据被篡改。</div>
						</div>


					</div>
				</div>
			</li>
			<li>
				<div className="titles">盈诺签赋强公证服务</div>
				<p className="serve-content">1.告知提醒：赋强公证协议及视频警示违约后果，加强司法意识； <br/>
					2.违约催告：维权方自催无效后，公证处催告，告知不还款将面临的严重法律后果；<br/>
					3.申请执行：盈诺签一键申请出具电子执行证书，保证效率；<br/>
					4.强制执行：债权人可借助公证处与法院建立的执行通道，通过无纸化、批量快速立案的方式，让公证执行证书得以快速执行，无须举证，诉讼等；<br/>
					5.失信人黑名单：被执行人无可被执行财产，将被直接纳入限高人员、失信人员名单，有效督促被执行人主动还款，为金融机构核销不良提供依据。</p>
			</li>
			<li>
				<div className="titles">赋强公证业务的核心价值</div>
				<p className="serve-content">
					1.免诉，网络赋强后债务人不还钱，不用打官司了，直接由公证处推送执行证书至法院立案执行。<br/>
					2.全网受理解决执行难题，申请执行不需要跑公证处现场了，直接网上系统操作，一键申请、查看处理进度，债权人有了大把的时间去拓展业务，做有意义的事情。<br/>
					3.合法合规、降低司法成本，网络赋强节约法院的审判资源，发挥公证法律服务的司法辅助价值。<br/>
					4.快速列入失信、限制高消费被执行人名单威慑力强。债务人一旦进入限高人员、失信人员名单后会多处受限，比如不能买飞机高铁票出行，不能正常申请银行贷款融资，限制买卖不动产，限制买车，限制参与政府采购、招投标、政府扶持等，更可怕的是子女不得在高大上的私立学校上学了，所以绝大多数债务人会因此积极还款执行。<br/>
				</p>
			</li>
			<li>
				<div className="titles">赋强公证的快与省</div>
				<p className="serve-content">
					快,是指赋强公证能够在发生债务人违约的情况下,快速进入法院的执行程序,最大限度挽回债权人的损失。普通的债权文书诉讼,往往要经历一审6个月、二审3个月的审限,再加上立案、答辩、调查等时间,要进入法院执行程序最长可能需要接近一年的时间。而通过赋强公证,出现债务人违约的情形时,债权人向公证机构提出申请,公证机构受理后两个工作日发出核查函,给予债务人或担保人5个工作日的答辩期,不抗辩或抗辩理由不成立的,5个工作日届满即出具执行证书（特殊情况需要再次调查的,延长两日）。这意味着,从债权人提出申请至出具执行证书,最快仅需7个工作日,债权人就可凭原赋强公证书及执行证书向有管辖权的法院申请强制执行。<br/>
					省,是指赋强公证大大节省债权人的时间成本和经济成本。一二审环节的律师费、诉讼费均不需要缴纳,出具执行证书的公证费可以列入执行标的,挽回债权人的损失。而公证费与律师费相比,并不是一个等级,公证费按千分比计算,律师费按百分比计算。凭公证债权文书 （赋强公证书+执行证书）在法院执行立案后,也可以快速将恶意债务人或担保人列为失信被执行人,限制其高消费,迫使其回归谈判桌。
				</p>
			</li>
			<li>
				<div className="titles">赋强公证申请流程</div>
				<div className="clearfix">
					<div className="serve-left">
						<p className="serve-content">
							办理所需材料： <br/>
							(1)申请人的主体资格证明；<br/>
							(2)需要赋予强制执行效力公证的债权文书及相关的债券证明;<br/>
							(3)公证员认为应当提供的其他材料。

						</p>
					</div>
					<div className="serve-right">
						<p className="serve-content">
							1公证申请人填写公证申请表、签署公证告知书和询问笔录;<br/>
							2公证员帮助双方起草、修改和完善相关法律文书;<br/>
							3公证处出具赋予债权文书强制执行效力公证书;<br/>
							4债务人一旦违约，债权人申请《执行证书》，并向法院执行庭申请强制执行。
						</p>
					</div>
				</div>
			</li>
			<li>
				<div className="titles">赋予强制执行效力的债权文书应当具备的条件：
				</div>
				<p className="serve-content">
					1债权文书具有给付货币、物品、有价证券的内容;<br/>
					2债权债务关系明确，债权人和债务人对债权文书有关给付内容无疑义;<br/>
					3债权文书中载明债务人不履行义务或不完全履行义务时，债务人愿意接受依法强制执行的承诺。
				</p>
			</li>
		</ul>
	</div>
	<div className="product-tips">
		<p>
			赋强公证，即赋予债权文书强制执行效力的公证。具有效率高、投入低等特点，特别适用于大批量的借贷逾期案件贷后处置。<br/>
			网络赋强公证与法院生效裁判、仲裁裁决具有同样的执行效力，不仅可以简便司法程序，还能节省债权人的人力物力，避免了线下异地奔波的麻烦。网络赋强公证在保障债权人交易安全的同时，答便捷、高效、经济的实现债权，成为当前社会巧治老赖，化解多元化债权纠纷，省时省钱省心的法律手段。<br/>
			有利于引导和服务金融主体运用公证手段防范金融风险，从源头上预防金融领域的法律纠纷、抑制金融犯罪、减少诉讼案件，又有利于健全和完善多元化纠纷解决机制，维护当事人、利害关系人的合法权益和社会公共利益，共同推动提升法治化营商环境。
		</p>
	</div>
	<div className="serve-boox">
		<div className="wrap">
			<div className="step-titles">
				<h3>产品服务</h3>
				<h4>Product Service</h4>
			</div>
			<div className="step-box">
				<div className="step-img"></div>
				<div className="left-bg"></div>
				<div className="right-bg"></div>
				<ul className="clearfix">
					<li className="step-border"></li>
					<li className="step01">
						<span className="step-title">实名认证</span>
						<span className="step-border">公安人口库、工商信息库、银联、三大运营商多系统交叉验证，8大实名认证方式</span>
						<p></p>
					</li>
					<li className="step02">
						<span className="step-title">合同编辑</span>
						<span className="step-border">合同在线编辑，实时上传，在线操作数据全程可控</span>
						<p></p>
					</li>
					<li className="step03 step-curent">
						<span className="step-title">智能审批</span>
						<span className="step-border">合同在线智能化审批，合同查看、内部抄送，审批流程一目了然</span>
						<p></p>
					</li>
					<li className="step04">
						<span className="step-title">智能模板</span>
						<span className="step-border">合同模板可设定变量，添加内容，并进行模版授权管理，轻松完成合同审批</span>
						<p></p>
					</li>
					<li className="step05">
						<span className="step-title">线上签约</span>
						<span className="step-border">在线实时快捷签署，提供批量签、一键签、视频签等多种签署方式</span>
						<p></p>
					</li>
					<li className="step06">
						<span className="step-title">意愿验证</span>
						<span className="step-border">活体人脸识别、银行卡要素、手机号、身份证等方式进行校验</span>
						<p></p>
					</li>
					<li className="step07 step-curent">
						<span className="step-title">现场存证</span>
						<span className="step-border">在线签署操作数据全程留痕和存证，合同和操作数据都会同步存储到公证处、司法鉴定中心、电子数据保全中心、互联网法院、仲裁委和CA机构</span>
						<p></p>
					</li>
					<li className="step08">
						<span className="step-title">合同管理</span>
						<span className="step-border">合同云端存储，分类统计、审核、归档，随时查看和检索下载，线上查验合同真伪</span>
						<p></p>
					</li>
					<li className="step09">
						<span className="step-title">到期提醒</span>
						<span className="step-border">合同履约智能跟进，合同到期后能及时续签</span>
						<p></p>
					</li>
					<li className="step10">
						<span className="step-title">司法配套</span>
						<span className="step-border">对接权威司法机构，可实现在线公证、司法鉴定、仲裁，对于经济纠纷还可实现在线智能仲裁、代理维权，快速解决法律纠纷</span>
						<p></p>
					</li>
					<li className="step11 step-curent">
						<span className="step-title">保险服务</span>
						<span className="step-border">联合华安保险、太平洋保险，独家定制诉讼险种，最高可赔偿1万元</span>
						<p></p>
					</li>
				</ul>
			</div>
		</div>
	</div>
    </div>
  )
}
