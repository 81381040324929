import React, { Component } from 'react'
import {Link, NavLink} from 'react-router-dom'
import logoUrl from '../../assets/image/logo.png'
import logoWUrl from '../../assets/image/logo-white.png'
import './Header.less'
import {Simulate} from "react-dom/test-utils";
import pointerUp = Simulate.pointerUp;

export default class Header extends Component {
  state = {
    scrollTop: 0,
    critical: 250
  }

  componentDidMount() {
    window.addEventListener('scroll', this.bindHandleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.bindHandleScroll);
  }
  bindHandleScroll = (event: any) => {
    console.log(event.srcElement.documentElement.scrollTop)
    this.setState({
      scrollTop: event.srcElement.documentElement.scrollTop
    })
  }

  goto = (url: string) => {
    window.open(url)
  }

  render() {
    const { scrollTop, critical } = this.state
    return (
      <div className={"header " + (scrollTop >= critical ? "header-white" : "")}>
        <div className="logo">
          <img src={scrollTop >= critical ? logoUrl : logoWUrl} alt="" />
        </div>
        <div className="nav">
          <NavLink className={'link ' + (scrollTop >= critical ? "link-black" : "")} to="/home">首 页</NavLink>
          <NavLink className={'link ' + (scrollTop >= critical ? "link-black" : "")} to="/product">产品服务</NavLink>
          <NavLink className={'link ' + (scrollTop >= critical ? "link-black" : "")} to="/about">关我我们</NavLink>
          <NavLink className={'link ' + (scrollTop >= critical ? "link-black" : "")} to="/contact">联系我们</NavLink>
          <div className={'link ' + (scrollTop >= critical ? "link-black" : "")} onClick={() => { this.goto('https://zjb.yingnuoqian.com/#/register') }} style={{cursor:"pointer"}}>E 诺链</div>
        </div>
        <div className="r-g">
          <div className={'btn ' + (scrollTop >= critical ? "btn-black" : "")} onClick={() => { this.goto('https://c.yingnuoqian.com/#/register') }}>注册</div>
          <div className={'btn ' + (scrollTop >= critical ? "btn-black" : "")} onClick={() => { this.goto('https://c.yingnuoqian.com/#/login') }}>登陆</div>
        </div>
      </div>
    )
  }
}
