import React, { Component } from 'react'
import banner1 from '../../assets/image/banner_01.jpg'
import banner3 from '../../assets/image/banner_03.jpg'
import partner_01 from '../../assets/image/partner_01.png'
import partner_02 from '../../assets/image/partner_02.png'
import partner_03 from '../../assets/image/partner_03.png'
import partner_04 from '../../assets/image/partner_04.png'
import partner_05 from '../../assets/image/partner_05.png'
import partner_06 from '../../assets/image/partner_06.png'
import partner_07 from '../../assets/image/partner_07.png'
import partner_08 from '../../assets/image/partner_08.png'
import partner_09 from '../../assets/image/partner_09.png'
import partner_10 from '../../assets/image/partner_10.png'
import Swiper from '../../components/Swiper/Swiper';
import './home.less';

export default class Home extends Component {

  render() {
    return (
      <div className="home">
        <Swiper>
          <div className="banner banner1">
            <img src={banner1} alt="" />
            <div className="describe">
              <div className="title">赋强公证</div>
              <div className="subtitle">全线上申请办理赋予债权文书强制执行效力的公证书</div>

            </div>
          </div>
          <div className="banner banner2">
            <img src={banner3} alt="" />
            <div className="describe">
              <div className="title">盈得未来 一诺签金</div>
              <div className="subtitle">在线电子合同签署得到完整可靠的电子签名文件</div>
            </div>
          </div>
          <div className="banner banner1">
            <img src={banner1} alt="" />
            <div className="describe">
              <div className="title">正据宝</div>
              <div className="subtitle">区块链存证不可逆证据追踪还原事实真相</div>
            </div>
          </div>
        </Swiper>
        <div className="flow">
          <div className="wrap">
            <div className="flow-title">
              <h3>电子合同 - 在线赋强 - 上链存证 - 执行出证 - 法院执行</h3><br/>
              <h4>Solve the Whole Process of Enterprise Contract Signing</h4>
            </div>
            <div className="flow-content">
              <ul className="clearfix">
                <li className="li_01">
                  <p className="flow-name">电子合同</p>
                  <p className="flow-describe">全终端快捷签署，合同批量上传、发起及签署，合同在线编辑数据全程可控，身份安全认证，合同智能管理</p>
                </li>
                <li className="li_02">
                  <p className="flow-name">电子签章</p>
                  <p className="flow-describe">印章及权限智能管控，合同在线智能化审批，支持多级用户管理体系，多签章权限分配及管理，合同履约智能跟进</p>
                </li>
                <li className="li_03">
                  <p className="flow-name">存证出证</p>
                  <p className="flow-describe">对接互联网法院、公证处，司法鉴定中心、 仲裁委等权威司法机构，保证存证数据的 权威性和不可复改:建立联盟区块链节点， 让更多企</p>
                </li>
                <li className="li_04">
                  <p className="flow-name">保全执行</p>
                  <p className="flow-describe">经公证机构核实出具的强制执行证书可直接向人民法院申请强制执行，确实保全受害人的合法资产不受损害。</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="step">
          <div className="wrap">
            <div className="serve-title">
              <h3>电子合同全生命周期服务</h3><br/>
              <h4>Full life cycle service of electronic contract</h4>
            </div>
            <div className="contract-box">
              <ul className="clearfix">
                <li className="contract01">
                  <span className="contract-title">发起签约</span>
                  <span className="arrows arrows-left"></span>
                </li>
                <li className="contract02">
                  <span className="contract-title">合同生成</span>
                  <span className="arrows arrows-left"></span>
                </li>
                <li className="contract03">
                  <span className="contract-title">发送链接</span>
                  <span className="arrows arrows-left"></span>
                </li>
                <li className="contract04">
                  <span className="contract-title">真人认证</span>
                  <span className="arrows arrows-left"></span>
                </li>
                <li className="contract05">
                  <span className="contract-title">在线签署</span>
                </li>
              </ul>
              <div className="arrows-bottom"></div>
              <ul className="clearfix">
                <li className="contract06">
                  <span className="contract-title">风险代理</span>
                  <span className="arrows arrows-right"></span>
                </li>
                <li className="contract07">
                  <span className="contract-title">智能仲裁</span>
                  <span className="arrows arrows-right"></span>
                </li>
                <li className="contract08">
                  <span className="contract-title">在线公证</span>
                  <span className="arrows arrows-right"></span>
                </li>
                <li className="contract09">
                  <span className="contract-title">存证出证</span>
                  <span className="arrows arrows-right"></span>
                </li>
                <li className="contract10">
                  <span className="contract-title">保全托管</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="partner">
          <div className="wrap">
            <div className="serve-title">
              <h3>合作伙伴</h3>
              <h4>Cooperation partners</h4>
            </div>
            <div className="partner-box">
              <div>
                {/* <img src="" alt="" /> */}
                <img src={partner_06} alt="" />
                <span>|</span>
                <img src={partner_09} alt="" />
                <span>|</span>
                <img src={partner_10} alt="" />
                <span>|</span>
                <img src={partner_01} alt="" />
              </div>
              <div>
                <img src={partner_02} alt="" />
                <span>|</span>
                <img src={partner_03} alt="" />
                <span>|</span>
                <img src={partner_04} alt="" />
                <span>|</span>
                <img src={partner_05} alt="" />
                <span>|</span>
                <img src={partner_07} alt="" />
                <span>|</span>
                <img src={partner_08} alt="" />
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}
