import React from 'react';
import './App.less';
import Header from './components/Header/Header'
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import Home from './pages/home/home';
import Product from './pages/product/product';
import About from './pages/about/about';
import Contact from './pages/contact/contact';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <div className="content">
          <Route path="/home" component={Home}></Route>
          <Route path="/product" component={Product}></Route>
          <Route path="/about" component={About}></Route>
          <Route path="/contact" component={Contact}></Route>
          <Redirect from="/" to="/home" />
        </div>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
