import React from 'react'
import './Footer.less'
import qrcode from '../../assets/image/code.jpg'
import beian from '../../assets/image/beian.png'

export default function Footer() {
  return (
    <div className="footer">
      <div className="wrap clearfix">
        <div className="footer-left clearfix">
          <img className="qrcode" src={qrcode} alt="" />
          <div className="user">
            <div className="phone">0717-6436663</div>
            <p>湖北盈诺网络科技有限公司-盈诺签-正据宝数据服务中心</p>
            <p>地址：中国 (湖北) 自贸区宜昌片区橘乡大道529号城东U谷3号</p>
            <p>网址：www.yingnuoqian.com www.aesd.cn www.enuoqian.com</p>
          </div>
        </div>
        <div className="footer-right"></div>
      </div>
      <div className="copyright">
        <p>© 2024-2029 盈诺签 湖北盈诺网络科技有限公司版权所有</p>
        <a target="_blank" href="https://beian.miit.gov.cn" rel="noreferrer" className="beian">鄂ICP备20007476号</a>
        <img className="beian-icon" src={beian} alt="" />
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42050202000528"
          className="beian" rel="noreferrer">鄂公网安备 42050202000528号</a>
        高企编号 GR202242005121
      </div>
    </div>
  )
}
