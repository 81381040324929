import React, { Component } from 'react'
import './Swiper.less'

interface Props {
  children: any[],
  duration?: number,
  timeInterval?: number
}

interface State {
  action: number,
  children: any[],
  height?: number,
  duration?: number,
  timeInterval?: number,
  testRef?: any
}

let timer: any;

export default class Swiper extends Component<Props> {
  state: State = {
    action: 0,
    children: [],
    height: 493,
    duration: 2000,
    timeInterval: 5000,
    testRef: null
  }

  componentDidMount() {
    let newChildren = [...this.props.children];
    this.setState({
      children: newChildren
    })
    clearTimeout(timer);
    this.change();
    
    const { duration, timeInterval} = this.props;
    console.log(this.props)
    this.setState({
      // duration: duration ?? 2000,
      // timeInterval: timeInterval ?? 5000,
    })
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // if (prevState.children.length !==  this.state.children.length) {
    //   console.log('testRef',this.state.testRef.clientHeight);
    //   console.log('testRef',this.state.testRef);
    //   let clientHeight = this.state.testRef.clientHeight
      
    //   setTimeout(() => {
    //     this.setState({
    //       height: clientHeight??500
    //     })
    //   }, 200)
    // }
  }

  componentWillUnmount() {
    clearTimeout(timer);
  }

  change = () => {
    timer = setTimeout(() => {
      this.change()
    }, this.state.timeInterval)
    const { action, children } = this.state
    if (action + 1 >= children.length) {
      this.setState({
        action: 0
      })
    } else {
      this.setState({
        action: action + 1
      })
    }
  }

  render() {
    const { action, children, height, duration } = this.state
    return (
      <div className="swiper" style={{height: height+'px'}}>
        {
          children.map((item: any, index: number) => {
            return <div key={index} ref={testRef => {(this.state.testRef as any) = testRef}} className={'swiper-item ' + (index === action ? "action" : '')} style={{transition: `all ${duration}ms`}}>{item}</div>
          })
        }
      </div>
    )
  }
}
